import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './FeaturedProjects.css';

function FeaturedProjects() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/projects/`)
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });
  }, []);

  return (
    <Container className="featured-projects-section">
      <h2 className="text-center">Featured Projects</h2>
      <Row className="featured-projects-grid">
        {projects.slice(0, 3).map(project => (
          <Col md={4} key={project.id} className="my-3">
            <div className="project-card">
              <img src={project.image_url} alt={project.title} className="project-card-img" />
              <div className="project-card-content">
                <h3 className="project-card-title">{project.title}</h3>
                <p className="project-card-text">{project.description.substring(0, 100)}...</p>
                <div className="project-card-tech">
                  {project.frontend_technologies.map(tech => (
                    <Badge key={tech.id} bg="primary" className="me-1">{tech.name}</Badge>
                  ))}
                  {project.backend_technologies.map(tech => (
                    <Badge key={tech.id} bg="success" className="me-1">{tech.name}</Badge>
                  ))}
                  {project.database_technologies.map(tech => (
                    <Badge key={tech.id} bg="danger" className="me-1">{tech.name}</Badge>
                  ))}
                </div>
                <div className="project-card-buttons">
                  <Link to={`/projects/${project.id}`} className="btn btn-primary">Learn More</Link>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default FeaturedProjects;