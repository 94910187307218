import React from 'react';
import './DownloadCV.css';

function DownloadCV() {
  const cvUrl = `${process.env.REACT_APP_API_URL}/api/download-cv/`;

  return (
    <div style={{ margin: '20px 0' }}>
      <a 
        href={cvUrl} 
        className="btn btn-primary download-cv-btn" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Download CV
      </a>
    </div>
  );
}

export default DownloadCV;
      