import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Image, Button, Badge } from 'react-bootstrap';
import ReactStars from 'react-rating-stars-component';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './ProjectDetailView.css'; // Import the CSS file

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ProjectDetailView = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${id}/`)
      .then(response => {
        setProject(response.data);
      })
      .catch(error => {
        console.error('Error fetching project:', error);
      });
  }, [id]);

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleFeedbackSubmit = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/projects/${id}/feedback/`, { feedback })
      .then(response => {
        console.log('Feedback submitted successfully', response.data);
        setFeedback('');
      })
      .catch(error => {
        console.error('Error submitting feedback:', error);
      });
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    axios.post(`${process.env.REACT_APP_API_URL}/api/projects/${id}/rate/`, { rating: newRating })
      .then(response => {
        console.log('Rating submitted successfully', response.data);
      })
      .catch(error => {
        console.error('Error submitting rating:', error);
      });
  };

  const renderChart = () => {
    if (!project) return null;
    const data = {
      labels: ['Frontend', 'Backend', 'Database'],
      datasets: [
        {
          label: 'Technologies Used',
          data: [
            project.frontend_technologies.length,
            project.backend_technologies.length,
            project.database_technologies.length,
          ],
          backgroundColor: ['#3498db', '#2ecc71', '#e74c3c'],
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Technologies Used',
        },
      },
    };

    return <Bar ref={chartRef} data={data} options={options} />;
  };

  return (
    <Container className="project-detail-section my-5">
      {project && (
        <>
          <Row>
            <Col md={8}>
              <h2>{project.title}</h2>
              {/* Render CKEditor content as HTML */}
              <div dangerouslySetInnerHTML={{ __html: project.description }} />
              <p><strong>Role:</strong> {project.role}</p>
              {/* Render CKEditor content as HTML */}
              <div dangerouslySetInnerHTML={{ __html: project.challenges }} />
              {/* Render CKEditor content as HTML */}
              <div dangerouslySetInnerHTML={{ __html: project.learnings }} />
              {/* Render CKEditor content as HTML */}
              <div dangerouslySetInnerHTML={{ __html: project.impact }} />
              {/* Optional client feedback */}
              {project.client_feedback && (
                <div>
                  <strong>Client Feedback:</strong>
                  <div dangerouslySetInnerHTML={{ __html: project.client_feedback }} />
                </div>
              )}
              {project.video_demo_url && (
                <p>
                  <Button href={project.video_demo_url} target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    Watch Demo
                  </Button>
                </p>
              )}
              <div className="mb-3">
                {project.frontend_technologies.map(tech => (
                  <Badge key={tech.id} bg="primary" className="me-1">{tech.name}</Badge>
                ))}
                {project.backend_technologies.map(tech => (
                  <Badge key={tech.id} bg="success" className="me-1">{tech.name}</Badge>
                ))}
                {project.database_technologies.map(tech => (
                  <Badge key={tech.id} bg="danger" className="me-1">{tech.name}</Badge>
                ))}
              </div>
              <div className="feedback-section mt-3">
                <textarea
                  placeholder="Leave your feedback"
                  value={feedback}
                  onChange={handleFeedbackChange}
                  rows="3"
                  className="form-control"
                />
                <Button onClick={handleFeedbackSubmit} className="btn btn-primary mt-2">
                  Submit Feedback
                </Button>
              </div>
              <div className="rating-section mt-3">
                <ReactStars
                  count={5}
                  value={rating}
                  onChange={handleRatingChange}
                  size={24}
                  activeColor="#ffd700"
                />
              </div>
            </Col>
            <Col md={4} className="image-section">
              <Image src={project.image_url} alt={project.title} fluid />
              <div className="d-grid gap-2">
                <Button href={project.live_link} target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-3">
                  Live Project
                </Button>
                <Button href={project.github_link} target="_blank" rel="noopener noreferrer" className="btn btn-secondary mt-2">
                  GitHub Repository
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="chart-section mt-5">
            <Col>
              {renderChart()}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ProjectDetailView;