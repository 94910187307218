import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'animate.css';
import './Hero.css';

function Hero() {
  const [heroData, setHeroData] = useState({});

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/hero/`)
      .then(response => {
        setHeroData(response.data);
      })
      .catch(error => {
        console.error('Error fetching hero data:', error);
      });
  }, []);

  return (
    <div className="hero-section" style={{ backgroundImage: `url(${heroData.background_image_url})` }}>
      <div className="overlay"></div>
      <Container className="hero-container">
        <Row className="align-items-center">
          <Col md={6} className="hero-image text-center text-md-left">
            <div className="hero-profile-image animate__animated animate__fadeInLeft">
              <img src={heroData.profile_image_url} alt="Profile" />
            </div>
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-center hero-content text-center text-md-left">
            <h1 className="hero-title animate__animated animate__fadeInDown">{heroData.title}</h1>
            <p className="hero-text animate__animated animate__fadeInUp">{heroData.subtitle}</p>
            <div className="hero-buttons">
              <Button href="/projects" className="mr-2 btn-primary hero-button animate__animated animate__pulse">{heroData.view_projects_button_text}</Button>
              <Button href="/contact" variant="secondary" className="hero-button animate__animated animate__pulse">{heroData.contact_me_button_text}</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Hero;