import React, { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Badge } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import './PortfolioCarousel.css'; // Import the CSS file

const PortfolioCarousel = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/projects/`)
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8}>
          <Carousel>
            {projects.map((project) => (
              <Carousel.Item key={project.id}>
                <img
                  className="d-block w-100"
                  src={project.image_url}
                  alt={project.title}
                />
                <Carousel.Caption>
                  <h3>{project.title}</h3>
                  <p>{project.description.substring(0, 100)}...</p>
                  <div>
                    {project.frontend_technologies.map(tech => (
                      <Badge bg="primary" className="me-1">{tech.name}</Badge>
                    ))}
                    {project.backend_technologies.map(tech => (
                      <Badge bg="success" className="me-1">{tech.name}</Badge>
                    ))}
                    {project.database_technologies.map(tech => (
                      <Badge bg="danger" className="me-1">{tech.name}</Badge>
                    ))}
                  </div>
                  <Link to={`/projects/${project.id}`} className="btn btn-primary mt-2">Learn More</Link>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default PortfolioCarousel;