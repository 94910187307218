import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Badge, Collapse } from 'react-bootstrap';
import PortfolioCarousel from './PortfolioCarousel';
import ProjectFilter from './ProjectFilter';
import ReactStars from 'react-rating-stars-component';
import './Projects.css'; // Import the CSS file

function Projects() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [expandedCard, setExpandedCard] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/projects/`)
      .then(response => {
        setProjects(response.data);
        setFilteredProjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });
  }, []);

  const handleFilterChange = (searchQuery, filters) => {
    const filtered = projects.filter(project =>
      project.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filters.frontend.length === 0 || filters.frontend.every(tech => project.frontend_technologies.some(t => t.name === tech))) &&
      (filters.backend.length === 0 || filters.backend.every(tech => project.backend_technologies.some(t => t.name === tech))) &&
      (filters.database.length === 0 || filters.database.every(tech => project.database_technologies.some(t => t.name === tech)))
    );
    setFilteredProjects(filtered);
  };

  const handleFeedbackChange = (projectId, event) => {
    setFeedback({ ...feedback, [projectId]: event.target.value });
  };

  const handleFeedbackSubmit = (projectId) => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/feedback/`, { feedback: feedback[projectId] })
      .then(response => {
        console.log('Feedback submitted successfully', response.data);
      })
      .catch(error => {
        console.error('Error submitting feedback', error);
      });
  };

  const toggleExpand = (projectId) => {
    setExpandedCard(expandedCard === projectId ? null : projectId);
  };

  return (
    <Container className="projects-section my-5">
      <h1>Projects</h1>
      <PortfolioCarousel />
      <ProjectFilter onFilterChange={handleFilterChange} />
      <Row className="mt-4">
        {filteredProjects.map(project => (
          <Col md={4} key={project.id} className="mb-4">
            <Card className="project-card">
              {project.image_url ? (
                <Card.Img variant="top" src={project.image_url} alt={project.title} />
              ) : (
                <p className="project-card-text" style={{ padding: '20px' }}>No image available</p>
              )}
              <Card.Body>
                <Card.Title className="project-card-title">{project.title}</Card.Title>
                <Card.Text className="project-card-text">{project.description}</Card.Text>
                <div>
                  {project.frontend_technologies.map(tech => (
                    <Badge key={tech.id} bg="primary" className="me-1">{tech.name}</Badge>
                  ))}
                  {project.backend_technologies.map(tech => (
                    <Badge key={tech.id} bg="success" className="me-1">{tech.name}</Badge>
                  ))}
                  {project.database_technologies.map(tech => (
                    <Badge key={tech.id} bg="danger" className="me-1">{tech.name}</Badge>
                  ))}
                </div>
                <div className="mt-3">
                  <Button href={`/projects/${project.id}`} variant="primary">View Details</Button>
                  <Button href={project.live_link} target="_blank" rel="noopener noreferrer" variant="primary">Live Project</Button>
                  <Button href={project.github_link} target="_blank" rel="noopener noreferrer" variant="secondary">GitHub Repository</Button>
                </div>
                <div className="mt-3">
                  <Button
                    onClick={() => toggleExpand(project.id)}
                    variant="link"
                    aria-controls={`collapse-${project.id}`}
                    aria-expanded={expandedCard === project.id}
                  >
                    {expandedCard === project.id ? 'Show Less' : 'Show More'}
                  </Button>
                  <Collapse in={expandedCard === project.id}>
                    <div id={`collapse-${project.id}`}>
                      <div className="mt-3">
                        <textarea
                          placeholder="Leave your feedback"
                          value={feedback[project.id] || ''}
                          onChange={(event) => handleFeedbackChange(project.id, event)}
                          rows="3"
                          className="form-control"
                        />
                        <Button onClick={() => handleFeedbackSubmit(project.id)} variant="primary" className="mt-2">Submit Feedback</Button>
                      </div>
                      <div className="mt-3">
                        <ReactStars
                          count={5}
                          value={project.rating || 0}
                          size={24}
                          edit={false}
                          activeColor="#ffd700"
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Projects;