import React from 'react';

function BookingSystem() {
  return (
    <div id="booking-system">
      <iframe
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ10V8wOMrzlmEtcbx-5aAY4QrCrOO4uqb4z-YtAvoAEXCF4hGV87uRBobWPRNMqNsE_YMQAdJ1L?gv=true"
        width="100%"
        height="600"
        frameBorder="0"
        title="Booking System"
      ></iframe>
    </div>
  );
}

export default BookingSystem;