import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import './ProjectFilter.css'; // Import the CSS file

function ProjectFilter({ onFilterChange }) {
  const [technologies, setTechnologies] = useState({ frontend: [], backend: [], database: [] });
  const [filters, setFilters] = useState({ frontend: [], backend: [], database: [] });
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/technologies/`)
      .then(response => {
        const frontend = response.data.filter(tech => tech.category === 'frontend');
        const backend = response.data.filter(tech => tech.category === 'backend');
        const database = response.data.filter(tech => tech.category === 'database');
        setTechnologies({ frontend, backend, database });
      })
      .catch(error => {
        console.error('Error fetching technologies:', error);
      });
  }, []);

  const handleFilterChange = (event) => {
    const { name, options } = event.target;
    const selectedOptions = Array.from(options).filter(option => option.selected).map(option => option.value);
    setFilters({ ...filters, [name]: selectedOptions });
    onFilterChange(searchQuery, { ...filters, [name]: selectedOptions });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    onFilterChange(event.target.value, filters);
  };

  const clearFilters = () => {
    setFilters({ frontend: [], backend: [], database: [] });
    setSearchQuery('');
    onFilterChange('', { frontend: [], backend: [], database: [] });
  };

  return (
    <Form className="project-filter">
      <Form.Group controlId="search">
        <Form.Label>Search Projects</Form.Label>
        <Form.Control type="text" value={searchQuery} onChange={handleSearchChange} placeholder="Search by project title" />
      </Form.Group>
      <Row>
        <Col md={4}>
          <Form.Group controlId="frontend">
            <Form.Label>Frontend Technologies</Form.Label>
            <Form.Control as="select" multiple name="frontend" onChange={handleFilterChange}>
              {technologies.frontend.map(tech => (
                <option key={tech.id} value={tech.name}>{tech.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="backend">
            <Form.Label>Backend Technologies</Form.Label>
            <Form.Control as="select" multiple name="backend" onChange={handleFilterChange}>
              {technologies.backend.map(tech => (
                <option key={tech.id} value={tech.name}>{tech.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="database">
            <Form.Label>Database Technologies</Form.Label>
            <Form.Control as="select" multiple name="database" onChange={handleFilterChange}>
              {technologies.database.map(tech => (
                <option key={tech.id} value={tech.name}>{tech.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Button variant="primary" onClick={clearFilters}>
        Clear Filters
      </Button>
    </Form>
  );
}

export default ProjectFilter;