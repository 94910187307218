import React, { useEffect } from 'react';

function LiveChat() {
  useEffect(() => {
    // Tawk.to integration
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function() {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/668317889d7f358570d5ee45/1i1o17s2n';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return <div id="live-chat"></div>;
}

export default LiveChat;