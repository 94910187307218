import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLinkedin, FaGithub, FaTwitter } from 'react-icons/fa';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <Container fluid>
        <Row>
          <Col md={6}>
            <p>&copy; 2024 Lawrence Omolo. All rights reserved.</p>
          </Col>
          <Col md={6} className="text-md-right">
            <a href="https://www.linkedin.com/in/lawrence-omolo-beng-hons-85989691/" className="mr-3"><FaLinkedin size={30} /></a>
            <a href="https://github.com/jushotheone" className="mr-3"><FaGithub size={30} /></a>
            <a href="https://twitter.com/larryomoloJusho"><FaTwitter size={30} /></a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;