import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { FaGuitar, FaBicycle, FaCode, FaHiking } from 'react-icons/fa';
import './AchievementBadges.css';

function AchievementBadges() {
  const achievements = [
    { icon: <FaGuitar size={30} />, description: 'Avid bass guitarist in a local band.' },
    { icon: <FaBicycle size={30} />, description: 'Participate in community volunteer initiatives.' },
    { icon: <FaCode size={30} />, description: 'Enjoy developing web and e-commerce applications.' },
    { icon: <FaHiking size={30} />, description: 'Maintain a healthy work-life balance through outdoor activities like cycling and hiking.' },
  ];

  return (
    <Container className="achievement-container">
      <h2 className="text-center">Interests and Achievements</h2>
      <Row>
        {achievements.map((achievement, index) => (
          <Col md={3} className="text-center mb-4" key={index}>
            <Card className="achievement-card">
              <Card.Body>
                {achievement.icon}
                <Card.Text className="mt-2">{achievement.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default AchievementBadges;
