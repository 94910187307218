import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Hero from './Hero';
import Skills from './Skills';
import FeaturedProjects from './FeaturedProjects';
import DownloadCV from './DownloadCV';
import axios from 'axios';
import './Home.css'; // Import the CSS file

function Home() {
  const [cvUrl, setCvUrl] = useState('');

  useEffect(() => {
    // Fetch the CV URL from the API
    axios.get(`${process.env.REACT_APP_API_URL}/api/projects/`)
      .then(response => {
        if (response.data.length > 0 && response.data[0].cv_url) {
          setCvUrl(response.data[0].cv_url);
        }
      })
      .catch(error => {
        console.error('Error fetching CV URL:', error);
      });
  }, []);

  return (
    <div className="main-container">
      <Hero />
      <Skills />
      <FeaturedProjects />
      <div className="text-center">
        <DownloadCV cvUrl={cvUrl} />
        <Link to="/contact" className="btn btn-primary">Contact Me</Link>
      </div>
    </div>
  );
}

export default Home;