import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaHtml5, FaCss3Alt, FaJsSquare, FaPython, FaDatabase } from 'react-icons/fa';
import './Skills.css';

function Skills() {
  return (
    <Container className="skills-section">
      <h2>Skills</h2>
      <Row>
        <Col md={2} sm={6} className="skill">
          <FaHtml5 className="skill-icon" />
          <p>Proficient in HTML5 for modern web development.</p>
        </Col>
        <Col md={2} sm={6} className="skill">
          <FaCss3Alt className="skill-icon" />
          <p>Experienced in CSS3 for responsive design.</p>
        </Col>
        <Col md={2} sm={6} className="skill">
          <FaJsSquare className="skill-icon" />
          <p>Skilled in JavaScript for interactive web applications.</p>
        </Col>
        <Col md={2} sm={6} className="skill">
          <FaPython className="skill-icon" />
          <p>Proficient in Python for backend development.</p>
        </Col>
        <Col md={2} sm={6} className="skill">
          <FaDatabase className="skill-icon" />
          <p>Experienced in SQL for database management.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Skills;