import React from 'react';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import Skills from './Skills';
import ProjectTimeline from './ProjectTimeline';
import AchievementBadges from './AchievementBadges';
import './About.css';

function About() {
  const skills = [
    { name: 'HTML', level: 90 },
    { name: 'CSS', level: 85 },
    { name: 'JavaScript', level: 80 },
    { name: 'Python', level: 70 },
    { name: 'React', level: 75 },
    { name: 'PostgreSQL', level: 65 },
    { name: 'MySQL', level: 60 },
    { name: 'Flask', level: 70 },
    { name: 'Django', level: 60 },
  ];

  return (
    <Container className="about-container">
      <h1 className="text-center title">About Me</h1>
      <Row>
        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Body>
              <Card.Title className="custom-title">My Journey</Card.Title>
              <Card.Text className="custom-text">
                Hi, I'm Lawrence Omolo, a passionate and versatile Full Stack Developer based in Sudbury, United Kingdom. With a background in Aerospace Manufacturing Engineering and extensive experience in the financial, technical, and insurance services sectors, I bring a unique blend of strategic management and technical expertise to my work.
              </Card.Text>
              <Card.Text className="custom-text">
                I pivoted into full stack development, honing my skills through a rigorous Digital Skills Bootcamp that focused on HTML, CSS, JavaScript, Python, React, MySQL, and Flask. This training, combined with my BEng in Aerospace Manufacturing Engineering, has equipped me with a solid foundation in software engineering principles and a keen interest in AI and automation.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Body>
              <Card.Title className="custom-title">Professional Experience</Card.Title>
              <Card.Text className="custom-text">
                Currently, I am the Founder and Managing Director of L.O.J Enterprise, where I lead the development of innovative tech solutions and oversee all technology strategies. I have successfully launched our flagship cookware brand, Ruoth, and expanded our market reach through a comprehensive online marketplace.
              </Card.Text>
              <Card.Text className="custom-text">
                My previous roles include Account Director and Senior Account Manager at Financial Times, where I managed client portfolios worth £4.3 million and spearheaded enhancements in digital product offerings. I have also held positions such as Senior New Business Client Manager at Pitney Bowes Inc., Associate Consultant at SEC Recruitment, and Recruitment Account Manager at Modis International.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-4 custom-card">
            <Card.Body>
              <Card.Title className="custom-title">Skills</Card.Title>
              {skills.map(skill => (
                <div key={skill.name} className="mb-3">
                  <h5 className="custom-text">{skill.name}</h5>
                  <ProgressBar now={skill.level} label={`${skill.level}%`} />
                </div>
              ))}
              <Skills />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ProjectTimeline />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AchievementBadges />
        </Col>
      </Row>
    </Container>
  );
}

export default About;
