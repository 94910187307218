import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar'; // Ensure the path is correct
import Home from './components/Home';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ProjectDetailView from './components/ProjectDetailView';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/style.css'; // Ensure you have global styles here

function App() {
  return (
    <Router>
      <div style={{ 
        backgroundImage: 'url(/path/to/nature-background.jpg)', 
        backgroundSize: 'cover', 
        minHeight: '100vh',
        color: '#2c3e50' 
      }}>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetailView />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;