import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import './ProjectTimeline.css';

function ProjectTimeline() {
  const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/projects/`)
      .then(response => {
        setProjects(response.data.slice(0, 5)); // Limit to 5 projects for concise display
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });
  }, []);

  const handleProjectClick = (project) => {
    setActiveProject(activeProject === project.id ? null : project.id);
  };

  return (
    <Container className="timeline-container">
      <h2 className="text-center">Projects</h2>
      <div className="timeline">
        {projects.length === 0 ? (
          <p>No projects available</p>
        ) : (
          projects.map((project) => (
            <div key={project.id} className="timeline-item">
              <div
                className={`timeline-card ${activeProject === project.id ? 'active' : ''}`}
                onClick={() => handleProjectClick(project)}
              >
                <h5 className="timeline-title">{project.title}</h5>
                {activeProject === project.id && (
                  <div className="timeline-content">
                    <p>{project.description.substring(0, 100)}...</p>
                    <div>
                      {project.frontend_technologies.map(tech => (
                        <Badge bg="primary" className="me-1" key={tech.id}>{tech.name}</Badge>
                      ))}
                      {project.backend_technologies.map(tech => (
                        <Badge bg="success" className="me-1" key={tech.id}>{tech.name}</Badge>
                      ))}
                      {project.database_technologies.map(tech => (
                        <Badge bg="danger" className="me-1" key={tech.id}>{tech.name}</Badge>
                      ))}
                    </div>
                    {project.video_demo_url && (
                      <video width="100%" controls>
                        <source src={project.video_demo_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <Link to={`/projects/${project.id}`} className="btn btn-primary mt-2">Learn More</Link>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </Container>
  );
}

export default ProjectTimeline;