import React, { useState } from 'react';
import LiveChat from './LiveChat';
import BookingSystem from './BookingSystem';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import './Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact-messages/`, formData);
      if (response.status === 201) {
        setShowSuccess(true);
        setShowError(false);
        setFormData({ name: '', email: '', message: '' });
      }
    } catch (error) {
      setShowSuccess(false);
      setShowError(true);
      console.error('There was an error submitting the form!', error);
    }
  };

  return (
    <Container className="contact-container my-5">
      <h1 className="text-center">Contact Me</h1>
      {showSuccess && <Alert variant="success">Your message has been sent successfully!</Alert>}
      {showError && <Alert variant="danger">There was an error sending your message. Please try again later.</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            placeholder="Enter your message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Send
        </Button>
      </Form>
      <LiveChat />
      <BookingSystem />
    </Container>
  );
}

export default Contact;